<template>
  <b-card class="accordion" role="tablist">
    <b-tooltip target="disabled-coming-soon">{{ $t('ia.general.comingSoon') }}</b-tooltip>
    <div 
      :class="`
        mt-1 position-relative 
        ${section_left.class ? section_left.class : ''}
        ${section_left.disabled ? 'disabled' : ''}
      `"
      v-for="(section_left, index) in sections"
      :key="index"
    >
      <div class="disabled-collapse" v-if="section_left.disabled" id="disabled-coming-soon"></div>
      <div 
        class="d-flex justify-content-between align-items-center position-relative" 
        @click="clickCollapse(`icon_plus_${index}`, index)"
        v-b-toggle="`collapse_box_${index}`"
      >
        <span><img :src="section_left.icon" class="size-icons-left"/>{{ section_left.title }}</span>
        <div class="container-plus-less-icon" :ref="`icon_plus_${index}`" v-show="!section_left.disabled">
          <span></span>
          <span></span>
        </div>
      </div>
      <b-collapse :id="`collapse_box_${index}`" accordion="options-accordion" role="tab">
        <b-button-group vertical :key="update_buttons_left">
          <b-button 
          :class="
            category_selected.section === section_left.section && category_selected.category === subsection.category ? 'button-option-colllapse-active' : 
            'button-option-colllapse'" 
            variant="button-option-colllapse"
            class="avenir-medium"
            @click="redirectCategory({section: section_left.section, category: subsection.category})"
            v-for="(subsection, index_subsection) in section_left.sub_sections"
            :key="index_subsection"
            :disabled="subsection.disabled"
            >            
            {{ subsection.title }}
          </b-button>
        </b-button-group>
      </b-collapse>
    </div>
    <template #footer>
      <div class="footer-sider-left" @click="$emit('show_modal_update_suscription')" v-if="all_plans.length > 0">
        <feather-icon icon="CodesandboxIcon" class="icon-footer-sider"></feather-icon>
        <div>
          <span class="d-block avenir-medium">{{credits >= 0 ? separatebycomma(credits) : '0'}} {{ $t('ia.sidebarLeft.remainingCredits') }}</span>
          <span v-if="plan_order !== all_plans.length - 1 && credits <= 200">{{ $t('ia.sidebarLeft.updateTo') }} {{ all_plans[plan_order + 1].name }}</span>
        </div>
      </div>   
    </template>
  </b-card>
</template>

<script>
import {
  VBToggle,
  BCollapse,
  BButton,
  BButtonGroup,
  BCard,
  BTooltip,
} from "bootstrap-vue";
import { separatebycomma } from '@/libs/utils/formats';
import { getUserData } from "@/libs/utils/user";

export default {
  name: 'siderLeftSections',

  components: {
    BCollapse,
    BButton,
    BButtonGroup,
    BCard,
    BTooltip,
  },
  directives: {
    'b-toggle': VBToggle
  },
  props: {
    category_selected: {
      type: Object
    },
    credits: {
      type: Number,
      default: 0
    },
    all_plans: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      separatebycomma,
      update_buttons_left: false,
      sections: [
        {
          title: this.$t('search.community'),
          icon: require('@/assets/images/icons/grid.svg'),
          section: 'community',
          sub_sections: [
            {
              title: this.$t('search.showcase')
            }
          ]
        },
        {
          title: this.$t('search.images'),
          icon: require('@/assets/images/icons/imageIcon.svg'),
          section: 'image',
          sub_sections: [
            {
              title: this.$t('search.textImage'),
              category: 'txt-to-image'
            }
          ]
        },
        {
          title: 'Chat',
          icon: require('@/assets/images/icons/chat.svg'),
          section: 'chat',
          sub_sections: [
            {
              title: this.$t('search.chat_gpt'),
              category: 'chat-gpt'
            }
          ]
        },
        {
          title: this.$t('search.video'),
          icon: require('@/assets/images/icons/cameraVideo.svg'),
          section: 'video',
          sub_sections: [
            {
              title: this.$t('ia.sidebarLeft.talkingPortraits'),
              category: 'talking-portrait'
            }
          ],
        },
        {
          title: this.$t('search.text'),
          icon: require('@/assets/images/icons/text.svg'),
          section: 'text',
          sub_sections: [
            {
              title: 'Social Post',
              category: 'social-post',
            },
            {
              title: 'Rewrite',
              category: 'rewrite',
            },
            {
              title: 'Product Review',
              category: 'product-review',
            },
            {
              title: 'Brand Pitch',
              category: 'brand-pitch',
            },
            {
              title: 'Email Campaign',
              category: 'email-campaign',
            },
            {
              title: 'Video Script',
              category: 'video-script',
            },
          ],
        },
        {
          class: 'border-top pt-1',
          title: this.$t('search.history'),
          icon: require('@/assets/images/icons/historyClock.svg'),
          section: 'histories',
          sub_sections: [
            {title: this.$t('lists.image'), category: 'image'},
            {title: 'Video', category: 'video'},
            {title: this.$t('search.text'), category: 'text'}
          ]
        },
      ],
    }
  },
  computed: {
    plan_order() {
      if (getUserData()) return getUserData().current_plan.order
      else return 0
    }
  },
  methods: {
    openModal() {
      if (this.plan_order !== this.all_plans.length - 1 && this.credits <= 200) {
        this.open_modal_plans_group = true; 
        this.update_modal = !this.update_modal;
      }
    },
    clickCollapse(icon_plus, index) {
      this.clearActive(index)
      const container_icon = this.$refs[icon_plus][0];
      container_icon.classList.toggle("transition-icon-plus");
    },
    clearActive(index) {
      for (let i = 0; i < this.sections.length; i++)  {
        if (i !== index) this.$refs[`icon_plus_${i}`][0].classList.remove('transition-icon-plus')
      }
    },
    redirectCategory(selected) {
      this.category_selected.section = selected.section;
      this.category_selected.category = selected.category;
      this.update_buttons_left = !this.update_buttons_left;
      this.$router.push({ name: 'brandme_AI', params: { section: selected.section, category: selected.category}}).catch(() => {})
    },
  }
}
</script>

<style lang="scss" scoped>
.accordion .card-body {
  overflow: scroll;
}
.accordion {
  padding: 0 1em;
  width: 100%;
  height: 100%;
  .disabled-collapse {
    cursor: default;
    background-color: rgba(255, 255, 255, 0.521);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    height: 100%;
  }
  .footer-sider-left {
    background: linear-gradient(118deg, #7367f0, rgba(115, 103, 240, 0.7));
    padding: 1em;
    color: white;
    display: flex;
    align-items: center;
    border-radius: 1em;
    cursor: pointer;
    .icon-footer-sider {
      width: 25px;
      height: 25px;
      margin-right: 1em;
    }

    &:hover {
      background: linear-gradient(118deg, #7267f0d0, rgba(114, 103, 240, 0.747));
    }
  }
  .size-icons-left {
    width: 2.4rem;
    height: 2.4rem;
    margin-right: 0.5rem;
  }

  .container-plus-less-icon {
    background: transparent;
    width: 30px;
    height: 30px;
    border: 0;
    position: relative;

    span {
      position: absolute;
      transition: 300ms;
      background: #999999;

      &:first-child {
        top: 25%;
        bottom: 25%;
        width: 10%;
        left: 45%;
      }
      &:last-child {
        left: 25%;
        right: 25%;
        height: 10%;
        top: 45%;
      }
    }
  }

  .transition-icon-plus span {
    transform: rotate(90deg);
  }
  .transition-icon-plus span:last-child {
    left: 50%;
    right: 50%;
  }
  .button-option-colllapse-active {
    margin-left: 2.1rem;
    display: flex;
    text-align: start;
    color: #fff;
    background-color: #6c757d;
    border-radius: 0.5rem !important;
  }
  .button-option-colllapse {
    margin-left: 2.1rem;
    display: flex;
    text-align: start;
    background-color: #fff;
    border-radius: 0.5rem !important;

    &:hover {
      color: #212529;
      background-color: #e9ecef;
    }
    &:disabled {
      background-color: #fff;
    }
  }
}
</style>
<style lang="scss">
.accordion {
  .card-footer {
    padding: 1em 0;
  }
}
</style>